<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />

      <v-spacer></v-spacer>
      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :value="selected"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:item.isCurrent="{ item }">
          <v-switch readonly v-model="item.isCurrent"></v-switch>
        </template>

        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>

        <template v-slot:item.actions="{ item }">
          <ActionsButtons
            :update-function="openDialog"
            :delete-function="doDelete"
            :formData="item"
            :updateIcon="' mdi-pencil-box-outline'"
            :deleteIcon="'mdi-trash-can-outline'"
            :updateButtonColor="''"
            :deleteButtonColor="''"
          />
        </template>
      </v-data-table>
      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>

    <ManageExamsDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :levels="levels"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :classes="classes"
      :subjects="subjects"
      :types="types"
      :papers="papers"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
    <confirm-delete-resolve ref="confirm" />
  </div>
</template>

<script>
import ManageExamsDialog from "./Forms/ManageExamsDialog.vue";
import { get, create, update, remove } from "./services";
import { get as getSubjects } from "../subjects/services";
import { get as getClasses } from "../school-classes/services";
import { getSchoolSubjects } from "../school-subjects/services";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    ManageExamsDialog,
  },
  name: "ManageExaminations",
  data() {
    return {
      subjects: [],
      classes: [],
      selected: [],
      types: [],
      levels: [],
      papers: [],
      title: "Manage Examinations",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      items: [],
      levels: [],
      streams: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Class",
          align: "start",
          sortable: false,
          value: "school_class.name",
          class: "primary--text",
        },
        {
          text: "Exam Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Subject",
          align: "start",
          sortable: false,
          value: "subject.name",
          class: "primary--text",
        },

        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date",
          class: "primary--text",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type.name",
          class: "primary--text",
        },

        {
          text: "Assessment Level",
          align: "start",
          sortable: false,
          value: "level.name",
          class: "primary--text",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "purpose",
          class: "primary--text",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
    user() {
      return this.$mixins.currentUser();
    },
  },

  methods: {
    searchOption() {},

    loadAllSubjects(data = null) {
      let params = {
        search: data ? data : null,
        size: 1000,
      };
      getSubjects(params).then((response) => {
        this.subjects = response.data[0].data.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        });
      });
    },

    getSchoolSubject() {
      getSchoolSubjects().then((response) => {
        this.subjects = response.data;
      });
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.classes = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                ...item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    getAllClasses() {
      this.$mixins.fetchByCategory("ALL_CLASSES").then((response) => {
        this.classes = response.map((item) => ({
          ...item,
          schoolClass: item,
        }));
      });
    },

    examsTypes() {
      let examsTypes =
        this.user?.location?.level?.id === 5
          ? "SCHOOL_LEVEL_EXAMS"
          : "EXAMINATIONS_TYPES";

      this.$mixins.fetchByCategory(examsTypes).then((response) => {
        this.types = response;
      });
    },
    getPapers() {
      this.$mixins.fetchByCategory("EXAM_PAPERS").then((response) => {
        this.papers = response;
      });
    },
    loadClassesAndSubjects() {
      if (this.user?.location?.level?.id === 5) {
        this.getSchoolClasses();
        this.getSchoolSubject();
      } else {
        this.getAllClasses();
        this.loadAllSubjects();
      }
    },

    init(params) {
      this.loading = true;
      get({
        ...params,
      })
        .then((response) => {
          this.loading = false;
          this.params.total = response.data.to;
          this.items = response.data
            ? response.data.data
                .map((item) => ({
                  ...item,

                  schoolClass: item.school_class,
                }))

                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(data, item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject(data) {
      let payload = {
        createdBy: data?.created_by,
        ...data,
      };
      this.loading = true;
      remove(payload)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    async doDelete(data, item) {
      this.$refs.confirm
        .show({
          title: "Delete Examination",
          message:
            "Are you sure you want to delete this item ? It cannot be undone.",
          loading: this.loading,
        })
        .then((result) => {
          if (result) {
            this.deleteObject(item);
          } else {
            this.loading = false;
          }
        });
    },

    searchOptions(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    getLevel() {
      this.$mixins.fetchByCategory("EDUCATION_LEVELS").then((response) => {
        this.levels = response;
      });
    },

    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getLevel();
    this.loadClassesAndSubjects();
    this.examsTypes();
    this.getPapers();
  },
};
</script>

<style scoped></style>
