<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="800px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Examination</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <!-- <v-col cols="6" sm="12" md="6">
                  <v-text-field
                    label="Examination Name"
                    v-model="formData.name"
                    required
                    :rules="isValid.description"
                    v-uppercase
                  ></v-text-field>
                </v-col> -->
                <v-col cols="6" sm="12" md="12">
                  <DatePicker
                    v-model="formData.date"
                    :label="'Examination Date'"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="12" md="6">
                  <v-autocomplete
                    return-object
                    :items="classes"
                    :item-text="'name'"
                    label="Select Class"
                    v-model="formData.schoolClass"
                    required
                    :rules="isValid.level"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6" sm="12" md="6">
                  <v-autocomplete
                    return-object
                    :items="types"
                    :item-text="'name'"
                    label="Select Type"
                    v-model="formData.type"
                    required
                    :rules="isValid.level"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    return-object
                    :items="subjects"
                    :item-text="'name'"
                    label="Select Subject"
                    v-model="formData.subject"
                    required
                    :rules="isValid.level"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    return-object
                    :items="papers"
                    :item-text="'name'"
                    label="Select this if has Multiple papers"
                    v-model="formData.paper"
                    required
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    return-object
                    :items="levels"
                    :item-text="'name'"
                    label="Examination Level"
                    v-model="formData.level"
                    required
                    :rules="isValid.level"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Examination Purpose"
                    v-model="formData.purpose"
                    required
                    :rules="isValid.description"
                    v-sentence-case
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="valid"
            :title="title"
          />
        </v-card-actions>

        <!-- <pre>{{ formData.schoolClass }}</pre>
        ..........................................
        <pre>{{ classes }}</pre> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },

    levels: {
      type: Array,
      required: true,
    },
    subjects: {
      type: Array,
      required: true,
    },

    classes: {
      type: Array,
      required: true,
    },
    papers: {
      type: Array,
      required: true,
    },

    types: {
      type: Array,
      required: true,
    },

    closeDialog: {
      type: Function,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },

  name: "SchoolClassesDialog",
  data: () => ({
    valid: true,
    isValid: {
      minScore: [
        (v) => !!v || " Name required",
        /// (v) => /.+[^#<>:;,?""*|/]+/.test(v) || 'Invalid Character',
      ],
      description: [(v) => !!v || " Name required"],
      level: [(v) => !!v || " Level required"],
      required: [(v) => !!v || " Field required"],
    },
  }),
  methods: {
    add() {
      let data = { ...this.formData };
      data.name = ` 0${this.formData.subject.code}${
        this.formData.paper ? "/" + this.formData.paper.descriptions : ""
      }-${
        this.formData.subject.name
      } ${this.formData.schoolClass.name.toUpperCase()} ${this.formData.type.name.toUpperCase()} EXAMINATION FOR ${this.formData.level.name.toUpperCase()}`;

      // data.schoolClass = this.formData;
      data.academicYear = this.academicYear;
      data.exam_code = this.formData.paper
        ? `0${this.formData.subject.code}/${this.formData?.paper?.descriptions}`
        : `0${this.formData.subject.code}`;

      this.save(data);
    },

    close() {
      this.closeDialog();
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Add";
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
  },
  mounted() {},
};
</script>
